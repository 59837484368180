<template>
  <q-list dense class="q-ml-lg">
    <q-item dense>
      <q-item-section class="text-kecil">
        <p class="q-ma-none row justify-end" style="height:20px"></p>
        <p class="q-ma-none row justify-end" style="height:20px">
          <strong>JUMLAH NILAI</strong>
        </p>
        <p class="q-ma-none row justify-end" style="height:20px">
          <strong>RATA-RATA NILAI</strong>
        </p>
        <p class="q-ma-none row justify-end" style="height:20px">
          <strong>NILAI RATA-RATA KELAS</strong>
        </p>
      </q-item-section>
      <q-item-section class="text-lebih-kecil">
        <p class="q-ma-none row justify-center" style="height:20px">
          <strong>Pengetahuan</strong>
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">{{ totalSiswa.pengetahuan }}</p>
        <p class="q-ma-none row justify-center" style="height:20px">{{ rerataSiswa.pengetahuan }}</p>
        <p class="q-ma-none row justify-center" style="height:20px">{{ rerataKelas.pengetahuan }}</p>
      </q-item-section>
      <q-item-section class="text-lebih-kecil">
        <p class="q-ma-none row justify-center" style="height:20px">
          <strong>Keterampilan</strong>
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">{{ totalSiswa.keterampilan }}</p>
        <p class="q-ma-none row justify-center" style="height:20px">{{ rerataSiswa.keterampilan }}</p>
        <p class="q-ma-none row justify-center" style="height:20px">{{ rerataKelas.keterampilan }}</p>
      </q-item-section>
      <q-item-section class="text-lebih-kecil">
        <p class="q-ma-none row justify-center" style="height:20px">
          <strong>Rata-Rata</strong>
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{
          (totalSiswa.pengetahuan + totalSiswa.keterampilan) / 2
          }}
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{
          (rerataSiswa.pengetahuan + rerataSiswa.keterampilan) /
          2
          }}
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{
          (parseFloat(rerataKelas.pengetahuan) +
          parseFloat(rerataKelas.keterampilan)) /
          2
          }}
        </p>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  props: ["totalSiswa", "rerataSiswa", "rerataKelas"]
};
</script>