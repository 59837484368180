<template>
  <div>
    <q-card-section
      class="text-center text-overline text-bold"
    >LAPORAN PENCAPAIAN HASIL BELAJAR PESERTA DIDIK</q-card-section>
    <q-list>
      <q-item class="row">
        <q-item-section class="col-8" style="font-size:12px">
          <p class="q-ma-none row" style="height:30px">
            <a class="col-2">Sekolah</a>
            <a class="col-10">: MAS Perguruan Islam Ar Risalah</a>
          </p>
          <p class="q-ma-none row" style="height:auto">
            <a class="col-2">Alamat</a>
            <a class="col-10">: {{ siswa.alamat_detail }}</a>
          </p>
          <p class="q-ma-none row" style="height:30px">
            <a class="col-2">Nama Siswa</a>
            <a class="col-10">
              :
              <strong>{{ siswa.label }}</strong>
            </a>
          </p>
          <p class="q-ma-none row" style="height:30px">
            <a class="col-2">NIK/NISN</a>
            <a class="col-10">: {{ siswa.nisn }}</a>
          </p>
        </q-item-section>
        <q-item-section class="col-4" style="font-size:12px">
          <p class="q-ma-none row" style="height:30px">
            <a class="col-4">Kelas</a>
            <a class="col-8">: {{ siswa.nama_kelas }}</a>
          </p>
          <p class="q-ma-none row" style="height:30px">
            <a class="col-4">Semester</a>
            <a class="col-8">: {{ siswa.semester }}</a>
          </p>
          <p class="q-ma-none row" style="height:30px">
            <a class="col-4">Tahun Ajaran</a>
            <a class="col-8">: {{ siswa.tahun_ajar }}</a>
          </p>
          <p class="q-ma-none row" style="height:30px"></p>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>
<script>
export default {
  props: ["siswa"]
};
</script>