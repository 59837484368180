<template>
  <q-card-section>
    <p class="text-bold q-mb-none">B. Pengetahuan dan Keterampilan</p>
    <q-markup-table
      class="q-ml-lg"
      flat
      bordered
      wrap-cells
      dense
      separator="cell"
      style="width: 100%"
    >
      <thead>
        <tr class="text-center">
          <th rowspan="2" style="width:5%">
            <strong>No</strong>
          </th>
          <th rowspan="2" style="width:30%">
            <strong>Mata Pelajaran</strong>
          </th>
          <th rowspan="2" style="width:10%">
            <strong>KKM</strong>
          </th>
          <th colspan="2" style="width:20%">
            <strong>Pengetahuan</strong>
          </th>
          <th colspan="2" style="width:20%">
            <strong>Keterampilan</strong>
          </th>
        </tr>
        <tr class="text-center">
          <th style="width:10%">
            <strong>Nilai</strong>
          </th>
          <th style="width:10%">
            <strong>Predikat</strong>
          </th>
          <th style="width:10%">
            <strong>Nilai</strong>
          </th>
          <th style="width:10%">
            <strong>Predikat</strong>
          </th>
        </tr>
      </thead>
      <tbody class="text-lebih-kecil">
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>Kelompok A (Wajib)</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.A" :key="val.id" style="max-height:20px;">
          <td class="text-kecil">{{ val.number }}</td>
          <td class="text-kecil">{{ val.nama_mapel }}</td>
          <td class="text-kecil">{{ val.kkm }}</td>
          <td class="text-kecil">
            {{ val.listNilai.nilai_pengetahuan }}
          </td>
          <td class="text-kecil">
            {{ val.listNilai.predikat_pengetahuan }}
          </td>
          <td class="text-kecil">
            {{ val.listNilai.nilai_keterampilan }}
          </td>
          <td class="text-kecil">
            {{ val.listNilai.predikat_keterampilan }}
          </td>
        </tr>
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>Kelompok B (Wajib)</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.B" :key="val.id">
          <td class="text-kecil">{{ val.number }}</td>
          <td class="text-kecil">{{ val.nama_mapel }}</td>
          <td class="text-kecil">{{ val.kkm }}</td>
          <td class="text-kecil">{{ val.listNilai.nilai_pengetahuan }}</td>
          <td class="text-kecil">{{ val.listNilai.predikat_pengetahuan }}</td>
          <td class="text-kecil">{{ val.listNilai.nilai_keterampilan }}</td>
          <td class="text-kecil">{{ val.listNilai.predikat_keterampilan }}</td>
        </tr>
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>Kelompok C (Peminatan)</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.C" :key="val.id">
          <td class="text-kecil">{{ val.number }}</td>
          <td class="text-kecil">{{ val.nama_mapel }}</td>
          <td class="text-kecil">{{ val.kkm }}</td>
          <td class="text-kecil">{{ val.listNilai.nilai_pengetahuan }}</td>
          <td class="text-kecil">{{ val.listNilai.predikat_pengetahuan }}</td>
          <td class="text-kecil">{{ val.listNilai.nilai_keterampilan }}</td>
          <td class="text-kecil">{{ val.listNilai.predikat_keterampilan }}</td>
        </tr>
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>LINTAS MINAT</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.limit" :key="val.id">
          <td class="text-kecil">{{ val.number }}</td>
          <td class="text-kecil">{{ val.nama_mapel }}</td>
          <td class="text-kecil">{{ val.kkm }}</td>
          <td class="text-kecil">{{ val.listNilai.nilai_pengetahuan }}</td>
          <td class="text-kecil">{{ val.listNilai.predikat_pengetahuan }}</td>
          <td class="text-kecil">{{ val.listNilai.nilai_keterampilan }}</td>
          <td class="text-kecil">{{ val.listNilai.predikat_keterampilan }}</td>
        </tr>
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>Kelompok D (Kekhasan)</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.D" :key="val.id">
          <td class="text-kecil">{{ val.number }}</td>
          <td class="text-kecil">{{ val.nama_mapel }}</td>
          <td class="text-kecil">{{ val.kkm }}</td>
          <td class="text-kecil">{{ val.listNilai.nilai_pengetahuan }}</td>
          <td class="text-kecil">{{ val.listNilai.predikat_pengetahuan }}</td>
          <td class="text-kecil">{{ val.listNilai.nilai_keterampilan }}</td>
          <td class="text-kecil">{{ val.listNilai.predikat_keterampilan }}</td>
        </tr>
      </tbody>
    </q-markup-table>
  </q-card-section>
</template>
<script>
export default {
  props: ["kelompok"],
};
</script>
