<template>
  <q-card-section>
    <p class="q-mb-none">
      <strong>C. Ekstrakulikuler</strong>
    </p>
    <div class="q-ml-lg">
      <q-markup-table
        flat
        bordered
        wrap-cells
        dense
        separator="cell"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width:5%">
              <strong>NO</strong>
            </th>
            <th style="width:35%">
              <strong>Kegiatan</strong>
            </th>
            <th style="width:10%">
              <strong>Nilai</strong>
            </th>
            <th style="width:50%">
              <strong>Deskripsi</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in siswa.ekskul" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.nilai }}</td>
            <td>{{ val.deskripsi_ekskul }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
    <div class="q-mt-sm">
      <p class="q-mb-none">
        <strong>D. Prestasi</strong>
      </p>
      <div class="q-ml-lg">
        <q-markup-table
          flat
          bordered
          wrap-cells
          dense
          separator="cell"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th style="width:5%">
                <strong>NO</strong>
              </th>
              <th style="width:35%">
                <strong>Kegiatan</strong>
              </th>
              <th style="width:60%">
                <strong>Deskripsi</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in siswa.prestasi" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ val.bidang }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
    <div class="q-mt-sm">
      <p class="q-mb-none">
        <strong>E. Ketidakhadiran</strong>
      </p>
      <div class="q-ml-lg">
        <q-markup-table
          flat
          bordered
          wrap-cells
          dense
          separator="cell"
          style="width: 250px"
        >
          <tbody v-if="siswa.kehadiran" class="text-bold">
            <tr>
              <td>Sakit</td>
              <td>:{{ siswa.kehadiran.sakit }} hari</td>
            </tr>
            <tr>
              <td>Izin</td>
              <td>:{{ siswa.kehadiran.izin }} hari</td>
            </tr>
            <tr>
              <td>Alfa</td>
              <td>:{{ siswa.kehadiran.alfa }} hari</td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
    <div class="q-mt-sm">
      <p class="q-mb-none">
        <strong>F. Catatan Wali Kelas</strong>
      </p>
      <div class="q-ml-lg">
        <q-card square flat bordered style="100%">
          <q-card-section>{{ siswa.catatan }}</q-card-section>
        </q-card>
      </div>
    </div>
    <div class="q-mt-sm">
      <p class="q-mb-none">
        <strong>G. Tanggapan Orang Tua / Wali</strong>
      </p>
      <div class="q-ml-lg">
        <q-card square flat bordered style="width:100%; height: 5em"></q-card>
      </div>
    </div>
  </q-card-section>
</template>
<script>
export default {
  props: ["siswa"],
  data() {
    return {};
  },
  mounted() {},
};
</script>
