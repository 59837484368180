<template>
  <q-card-section class="q-py-none">
    <q-markup-table
      class="q-ml-lg"
      flat
      bordered
      wrap-cells
      dense
      separator="cell"
      style="width: 100%"
    >
      <thead>
        <tr class="text-center">
          <th rowspan="2" style="width:5%">
            <strong>No</strong>
          </th>
          <th rowspan="2" style="width:30%">
            <strong>Mata Pelajaran</strong>
          </th>
          <th rowspan="2" style="width:10%">
            <strong>KKM</strong>
          </th>
          <th colspan="2" style="width:20%">
            <strong>Pengetahuan</strong>
          </th>
          <th colspan="2" style="width:20%">
            <strong>Keterampilan</strong>
          </th>
        </tr>
        <tr class="text-center">
          <th style="width:10%">
            <strong>Nilai</strong>
          </th>
          <th style="width:10%">
            <strong>Predikat</strong>
          </th>
          <th style="width:10%">
            <strong>Nilai</strong>
          </th>
          <th style="width:10%">
            <strong>Predikat</strong>
          </th>
        </tr>
      </thead>
      <tbody class="text-kecil">
        <tbody v-for="(val,i) in nilai" :key="i" style="display: contents;">
        <tr v-if="val.ganti" class="bg-grey-5">
          <td colspan="7">
            <strong>{{val.ganti}}</strong>
          </td>
        </tr>
        
        <tr v-else>
          <td>{{ val.number }}</td>
          <td>{{ val.nama_mapel }}</td>
          <td>{{ val.kkm }}</td>
          <td>{{ val.listNilai.nilai_pengetahuan }}</td>
          <td>{{ val.listNilai.predikat_pengetahuan }}</td>
          <td>{{ val.listNilai.nilai_keterampilan }}</td>
          <td>{{ val.listNilai.predikat_keterampilan }}</td>
        </tr>

        </tbody>
      </tbody>
    </q-markup-table>
  </q-card-section>
</template>
<script>
export default {
  props: ["nilai"],
  mounted(){
    
  }
};
</script>
