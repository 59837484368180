<template>
  <q-card-section class="q-py-none">
    <q-markup-table
      flat
      bordered
      wrap-cells
      dense
      separator="cell"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th style="width:5%">
            <strong>No</strong>
          </th>
          <th style="width:35%">
            <strong>MATA PELAJARAN</strong>
          </th>
          <th style="width:10%">
            <strong>Aspek</strong>
          </th>
          <th style="width:50%">
            <strong>Deskripsi</strong>
          </th>
        </tr>
      </thead>

      <tbody></tbody>
      <tbody v-for="(el, i) in nilai" :key="i" style="display: contents">
        <tr v-if="el.ganti">
          <td colspan="4">
            <strong>{{ el.ganti }}</strong>
          </td>
        </tr>
        <tbody v-else style="display: contents;">

        <tr>
          <td rowspan="2">{{ el.number }}</td>
          <td rowspan="2" style="text-align:center;vertical-align:center">
            {{ el.nama_mapel }}
          </td>
          <td>Pengetahuan</td>
          <td class="text-lebih-kecil deskripsi_content">
            {{ el.listNilai.deskripsi_pengetahuan }}
          </td>
        </tr>
        <tr class="bg-grey-3">
          <td>Keterampilan</td>
          <td class="text-lebih-kecil deskripsi_content">
            {{ el.listNilai.deskripsi_keterampilan }}
          </td>
        </tr>
        </tbody>
      </tbody>
    </q-markup-table>
  </q-card-section>
</template>
<script>
export default {
  props: ["nilai"],
  data() {
    return {};
  },
  mounted() {
    // console.log(this.nilai);
  },
  computed: {
    // kelompok() {
    //   return this.kel
    // }
  },
};
</script>

<style lang="scss" scope>
.deskripsi_content {
  max-height: 70px !important;
}
</style>
