<template>
  <q-card-section>
    <p>
      <strong>Deskripsi Pengetahuan dan Keterampilan</strong>
    </p>
    <q-markup-table
      flat
      bordered
      wrap-cells
      dense
      separator="cell"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th style="width:5%">
            <strong>No</strong>
          </th>
          <th style="width:35%">
            <strong>MATA PELAJARAN</strong>
          </th>
          <th style="width:10%">
            <strong>Aspek</strong>
          </th>
          <th style="width:50%">
            <strong>Deskripsi</strong>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="4">
            <strong>Kelompok A (Wajib)</strong>
          </td>
        </tr>

        <div
          v-for="el in kelompok.A"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_pengetahuan }}
            </td>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_keterampilan }}
            </td>
          </tr>
        </div>

        <tr>
          <td colspan="4">
            <strong>Kelompok B (Wajib)</strong>
          </td>
        </tr>
        <div
          v-for="el in kelompok.B"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_pengetahuan }}
            </td>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_keterampilan }}
            </td>
          </tr>
        </div>

        <tr>
          <td colspan="4">
            <strong>Kelompok C (Peminatan)</strong>
          </td>
        </tr>
        <div
          v-for="el in kelompok.C"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_pengetahuan }}
            </td>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_keterampilan }}
            </td>
          </tr>
        </div>

        <tr>
          <td colspan="4">
            <strong>LINTAS MINAT</strong>
          </td>
        </tr>
        <div
          v-for="el in kelompok.limit"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_pengetahuan }}
            </td>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_keterampilan }}
            </td>
          </tr>
        </div>

        <tr>
          <td colspan="4">
            <strong>Kelompok D (Kekhasan)</strong>
          </td>
        </tr>
        <div
          v-for="el in kelompok.D"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_pengetahuan }}
            </td>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <td class="text-lebih-kecil deskripsi_content">
              {{ el.listNilai.deskripsi_keterampilan }}
            </td>
          </tr>
        </div>
      </tbody>
    </q-markup-table>
  </q-card-section>
</template>
<script>
export default {
  props: ["kelompok"],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    // kelompok() {
    //   return this.kel
    // }
  }
};
</script>

<style lang="scss" scope>
.deskripsi_content {
  max-height: 70px !important;
}
</style>
