<template>
  <q-card-section horizontal class="row justify-between q-mt-none">
    <q-card-section class="col-4 text-center">
      <a>Mengetahui,</a>
      <br />
      <a>Orang Tua / Wali</a>
      <br />
      <br />
      <br />..............................
    </q-card-section>
    <q-card-section class="col-4 text-center">
      <a>Padang, {{ date }}</a>
      <br />
      <a>Wali Kelas</a>
      <br />
      <br />
      <br />{{ kelas.nama_walas }}
    </q-card-section>
  </q-card-section>
</template>

<script>
import moment from "moment";
export default {
  props: ["kelas"],
  data() {
    return {
      date: moment().format("DD MMMM YYYY"),
    };
  },
};
</script>
