<template>
  <div
    class="main row justify-center items-start bg-grey-7 q-gutter-xl"
    style="font-size:12px;"
  >
    <q-card flat bordered style="width:210mm; " class="q-mt-xl ">
      <q-card-section class="row justify-between">
        <q-btn
          dense
          outline
          color="primary"
          label="Download PDF"
          :disable="!selSiswa"
          v-can="['WALAS']"
          @click="download()"
        />
        <!-- <q-btn dense outline @click="logData"></q-btn> -->
        <div class="row q-gutter-sm">
          <q-select
            dense
            outlined
            v-model="selKelas"
            label="Kelas"
            :options="kelas"
            style="width:200px"
            @input="prosesData"
          />
          <q-select
            dense
            outlined
            v-model="selSiswa"
            label="Siswa"
            :options="siswa"
            style="width:200px"
            @input="prepareNilai"
          />
        </div>
      </q-card-section>
    </q-card>

    <q-card v-if="selSiswa" style="width: 210mm;">
      <div class="paper" ref="cover">
        <q-card flat class=" column justify-between" style="height: 330mm">
          <q-card-section class="column items-center">
            <p style="font-size:18px">
              <strong>LAPORAN PENCAPAIAN HASIL BELAJAR PESERTA DIDIK</strong>
            </p>
            <p v-if="selJenjang == 'MA'" style="font-size:18px">
              <strong>MADRASAH 'ALIYAH SWASTA (MAS)</strong>
            </p>
            <p v-else style="font-size:18px">
              <strong
                >SEKOLAH MENENGAH PERTAMA (SMP) PERGURUAN ISLAM AR
                RISALAH</strong
              >
            </p>
            <p class="text-overline" style="font-size:14px">
              PERGURUAN ISLAM AR RISALAH
            </p>
          </q-card-section>
          <q-card-section
            class="column justify-evenly text-center"
            style="height:225mm"
          >
            <div>
              <img
                src="../../public/img/Logo Perguruan Only.png"
                style="height:75mm"
              />
            </div>
            <div v-if="selSiswa">
              <p class="q-mb-xs" style="font-size:14px">
                <strong>Nama Peserta Didik:</strong>
              </p>
              <p style="font-size:18px">
                <strong>{{ selSiswa.label }}</strong>
              </p>
              <p class="q-mb-xs" style="font-size:14px">
                <strong>NIS/NISN:</strong>
              </p>
              <p v-if="selSiswa.nisn != null" style="font-size:18px">
                <strong>{{ selSiswa.nisn }}</strong>
              </p>
              <p v-else style="font-size:18px">
                <strong>N/A</strong>
              </p>
            </div>
          </q-card-section>
          <q-card-section class="text-center">
            <p style="font-size:18px">
              <strong>KEMENTRIAN AGAMA</strong>
            </p>
            <p style="font-size:18px">
              <strong>REPUBLIK INDONESIA</strong>
            </p>
          </q-card-section>
        </q-card>
      </div>
      <div class="paper" ref="alamat">
        <q-card flat class=" column " style="height: 330mm">
          <q-card-section class="text-center">
            <p style="font-size:18px">
              <strong>LAPORAN PENCAPAIAN PESERTA DIDIK</strong>
            </p>
            <div v-if="selJenjang == 'MA'">
              <p style="font-size:18px">
                <strong>MADRASAH 'ALIYAH</strong>
              </p>
              <p style="font-size:18px">
                <strong>MA</strong>
              </p>
            </div>
            <div v-else>
              <p style="font-size:18px">
                <strong>SEKOLAH MENENGAH PERTAMA</strong>
              </p>
              <p style="font-size:18px">
                <strong>SMP</strong>
              </p>
            </div>
          </q-card-section>
          <q-card-section class="q-px-none">
            <q-markup-table dense flat>
              <tr>
                <td>Nama Sekolah</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input
                    dense
                    readonly
                    value="Madrasah 'Aliyah Swasta (MAS) Perguruan Islam Ar-Risalah"
                  ></q-input>
                </td>
              </tr>
              <tr>
                <td>NPSN</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="10263742"></q-input>
                </td>
              </tr>
              <tr>
                <td>NIS/NSM</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="305/131213710005"></q-input>
                </td>
              </tr>
              <tr>
                <td>Alamat Sekolah</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input
                    dense
                    readonly
                    value="Kampus Ar Risalah Air Dingin RT .01 RW .IX"
                  ></q-input>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="row justify-between" style="width:75%">
                  <div
                    class="row justify-between items-center"
                    style="width:35%"
                  >
                    <span>Kode Pos</span>
                    <q-input
                      dense
                      readonly
                      value="25174"
                      style="width:65%"
                    ></q-input>
                  </div>
                  <div
                    class="row justify-between items-center"
                    style="width:60%"
                  >
                    <span>Telpon</span>
                    <q-input
                      dense
                      readonly
                      value="0751-7877114, 9824888"
                    ></q-input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Desa/Kelurahan</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="Balai Gadang"></q-input>
                </td>
              </tr>
              <tr>
                <td>Kecamatan</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="Koto Tengah"></q-input>
                </td>
              </tr>
              <tr>
                <td>Kota/Kabupaten</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="Padang"></q-input>
                </td>
              </tr>
              <tr>
                <td>Provinsi</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="Sumatera Barat"></q-input>
                </td>
              </tr>
              <tr>
                <td>Website</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input
                    dense
                    readonly
                    value="http://www.arrisalah-sumber.sch.id"
                  ></q-input>
                </td>
              </tr>
              <tr>
                <td>E-mail</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input
                    dense
                    readonly
                    value="mas-arrisalah@yahoo.co.id"
                  ></q-input>
                </td>
              </tr>
            </q-markup-table>
          </q-card-section>
        </q-card>
      </div>
      <div ref="page_1" class="paper  column justify-between no-wrap">
        <Header :siswa="selSiswa"></Header>
        <Sikap :siswa="selSiswa"></Sikap>
        <Footer class="q-mt-md" :kelas="selKelas"></Footer>
      </div>
      <!-- <Nilai
          class="q-mt-none"
          :kelompok="{
            A: nilaiKelompok_A,
            B: nilaiKelompok_B,
            C: nilaiKelompok_C,
            limit: nilaiLimit,
            D: nilaiKelompok_D,
          }"
        ></Nilai> -->
      <span v-for="(val, i) in newformat" :key="i">
        <div ref="page_2" class="paper column justify-between no-wrap">
          <div>
            <Header :siswa="selSiswa"></Header>
            <p v-if="i == 0" class="text-bold q-mb-none">
              B. Pengetahuan dan Keterampilan
            </p>
            <newNilai :nilai="val"></newNilai>
            <Rerata
              v-if="i == newformat.length - 1"
              :totalSiswa="totalSiswa"
              :rerataSiswa="rerataSiswa"
              :rerataKelas="rerataKelas"
            ></Rerata>
          </div>
          <Footer
            v-if="i == newformat.length - 1"
            class="q-mt-md"
            :kelas="selKelas"
          ></Footer>
        </div>
      </span>

      <span v-for="(el, j) in newdeskripsi" :key="j">
        <div ref="page_3" class="paper column justify-between no-wrap">
          <div>
            <Header :siswa="selSiswa"></Header>

            <p v-if="j == 0" class=" q-mb-none">
              <strong>Deskripsi Pengetahuan dan Keterampilan</strong>
            </p>
            <newDesc :nilai="el"></newDesc>
          </div>
          <Footer
            v-if="j == newdeskripsi.length - 1"
            class="q-mt-md"
            :kelas="selKelas"
          ></Footer>
        </div>
      </span>

      <div ref="page_4" class="paper column justify-between no-wrap">
        <div>
          <Header :siswa="selSiswa"></Header>
          <Lainya :siswa="selSiswa"></Lainya>
        </div>
        <Footer class="q-mt-md" :kelas="selKelas"></Footer>
      </div>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import moment from "moment";
import { jsPDF } from "jspdf";
import Header from "@/views/HasilRaportDigital/Header";
import Sikap from "@/views/HasilRaportDigital/Sikap";
import Nilai from "@/views/HasilRaportDigital/Nilai";
import newNilai from "@/views/NewRaportDigital/Nilai";
import newDesc from "@/views/NewRaportDigital/Deskripsi";
import Rerata from "@/views/HasilRaportDigital/Rerata";
import Deskripsi from "@/views/HasilRaportDigital/Deskripsi";
import Lainya from "@/views/HasilRaportDigital/Lainya";
import Footer from "@/views/HasilRaportDigital/Footer";

export default {
  components: {
    Header,
    Sikap,
    Nilai,
    Rerata,
    Footer,
    Deskripsi,
    Lainya,
    newNilai,
    newDesc,
  },
  data() {
    return {
      kunci: 0,
      gambar: null,
      user: {},
      kelas: [],
      selKelas: null,
      selJenjang: localStorage.getItem("jenjang"),

      nilaiKelompok_A: [],
      nilaiKelompok_B: [],
      nilaiKelompok_C: [],
      nilaiLimit: [],
      nilaiKelompok_D: [],

      deskripsi_page1: [],
      deskripsi_page2: [],
      deskripsi_page3: [],
      deskripsi_page4: [],

      newformat: [],
      newdeskripsi: [],

      kehadiran: { sakit: 0, izin: 0, alfa: 0 },

      totalSiswa: {
        pengetahuan: 0,
        keterampilan: 0,
      },

      rerataSiswa: {
        pengetahuan: 0,
        keterampilan: 0,
      },

      rerataKelas: {
        pengetahuan: 0,
        keterampilan: 0,
      },

      selSiswa: null,
      siswa: [],
    };
  },
  async created() {
    await this.getUser();
    await this.getKelas();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async prosesData() {
      this.$q.loading.show({
        message: "Memuat Data....",
      });
      await this.getListSiswa();

      // pemrosesan data untuk seluruh siswa
      await this.prepareRaport();
      this.prepareRerataKelas();

      //pemrosesan data untuk siswa terpilih
      this.prepareNilai();
      this.$q.loading.hide();
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getKelas() {
      let resp = await this.$http.get(
        `/hasil/raport/getKelas/${this.user.id}/${
          this.user.is_kepala_sekolah
        }/${this.user.is_guru_bk}/${
          this.user.is_super_user
        }/${localStorage.getItem("jenjang")}`
      );
      this.kelas = resp.data;
    },
    logData() {
      console.log(this.selSiswa);
    },
    async saveRaport() {
      let data_raport = {
        id_siswa: this.selSiswa.id,
        id_tahun_ajaran: localStorage.getItem("id_tahun_ajaran"),
        nama_rombel: this.selSiswa.nama_kelas,
        wali_kelas: this.selKelas.nama_walas, // dummy
        tanggal: moment().format("YYYY-MM-DD"), // dummy
        sakit: this.selSiswa.kehadiran.sakit,
        izin: this.selSiswa.kehadiran.izin,
        alfa: this.selSiswa.kehadiran.alfa,
        nilai_spiritual: this.selSiswa.nilai_spiritual,
        nilai_sosial: this.selSiswa.nilai_sikap,
        rerata_pengetahuan: this.rerataKelas.pengetahuan,
        rerata_keterampilan: this.rerataKelas.keterampilan,
        rerata_total:
          this.rerataKelas.pengetahuan + this.rerataKelas.keterampilan,
      };
      let resp = await this.$http.post(`/hasil/raport/rekapdata`, data_raport);
      let id_raport = resp.data;
      if (id_raport == 0) {
        resp = await this.$http.get(
          `/hasil/raport/getid/${this.selSiswa.id}/${localStorage.getItem(
            "id_tahun_ajaran"
          )}`
        );
        id_raport = resp.data.id;
      }

      let nilai = [];
      for (let item of this.selSiswa.listmapel) {
        let row = {
          id_raport: id_raport,
          tipe: "",
          nama_mapel: item.nama_mapel,
          kkm: item.kkm,
          nilai_pengetahuan: item.listNilai.nilai_pengetahuan,
          predikat_pengetahuan: item.listNilai.predikat_pengetahuan,
          deskripsi_pengetahuan: item.listNilai.deskripsi_pengetahuan,
          nilai_keterampilan: item.listNilai.nilai_keterampilan,
          predikat_keterampilan: item.listNilai.predikat_keterampilan,
          deskripsi_keterampilan: item.listNilai.deskripsi_keterampilan,
        };
        if (item.kelompok == "A : Wajib") {
          row.tipe = "A";
        } else if (item.kelompok == "B : Wajib") {
          row.tipe = "B";
        } else if (item.kelompok == "C : Peminatan") {
          row.tipe = "C";
        } else if (item.kelompok == "Lintas Minat") {
          row.tipe = "C_LIMIT";
        } else if (item.kelompok == "D : Kekhasan") {
          row.tipe = "D";
        }
        nilai.push(row);
      }
      await this.$http.post(`/hasil/raport/rekapnilai`, nilai);
    },
    async prepareRaport() {
      await this.getNilaiAkademik();
      await this.getNilaiDetail();
      await this.getEkskul();
      await this.getPrestasi();
      await this.getKehadiran();
      this.selSiswa = this.siswa[0];
    },
    prepareNilai() {
      this.getKelompok();
      this.getnilaipage();
      this.getdeskripsipage();
      this.getSummaryNilaiSiswa();
      this.resizeFont();
    },
    resize(page) {
      let hScroll = page.scrollHeight;
      let hClient = page.clientHeight;

      // console.log(page);

      let ratio = 1.0;
      while (hScroll > hClient) {
        ratio -= 0.01;
        page.style.setProperty("font-size", ratio + "em", "important");
        hScroll = page.scrollHeight;
        hClient = page.clientHeight;
      }
    },
    resizeFont() {
      this.resize(this.$refs.page_1);

      this.$nextTick(() => {
        for (let i in this.$refs.page_2) {
          this.resize(this.$refs.page_2[i]);
        }

        for (let i in this.$refs.page_3) {
          this.resize(this.$refs.page_3[i]);
        }
      });

      this.resize(this.$refs.page_4);
    },
    async download() {
      this.$q.loading.show();
      await this.saveRaport();
      const doc = new jsPDF("p", "mm", "a4");
      const gambar = [];
      let page = await html2canvas(this.$refs.cover, { scale: 2 });
      gambar.push(page.toDataURL("image/png"));
      page = await html2canvas(this.$refs.alamat, { scale: 2 });
      gambar.push(page.toDataURL("image/png"));
      page = await html2canvas(this.$refs.page_1, { scale: 2 });
      gambar.push(page.toDataURL("image/png"));

      for (let i in this.$refs.page_2) {
        page = await html2canvas(this.$refs.page_2[i], { scale: 2 });
        gambar.push(page.toDataURL("image/png"));
      }

      for (let i in this.$refs.page_3) {
        page = await html2canvas(this.$refs.page_3[i], { scale: 2 });
        gambar.push(page.toDataURL("image/png"));
      }

      page = await html2canvas(this.$refs.page_4, { scale: 2 });
      gambar.push(page.toDataURL("image/png"));

      for (let i in gambar) {
        doc.addImage(gambar[i], "JPEG", 0, 0, 210, 297, `alias${i}`, "MEDIUM");
        if (i < gambar.length - 1) {
          doc.addPage();
        }
      }
      doc.save(
        `Raport ${this.selSiswa.label} Periode ${localStorage.getItem(
          "nama_tahun_ajaran"
        )}.pdf`
      );
      this.$q.loading.hide();
    },
    getdeskripsipage() {
      let count = 0;
      let idx = 0;
      let new_desc = [];

      new_desc.push([]);
      count++;
      new_desc[idx].push({ ganti: "Kelompok A (Wajib)" });
      for (let item of this.nilaiKelompok_A) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      count++;
      new_desc[idx].push({ ganti: "Kelompok B (Wajib)" });
      for (let item of this.nilaiKelompok_B) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      count++;
      new_desc[idx].push({ ganti: "Kelompok C (Peminatan)" });
      for (let item of this.nilaiKelompok_C) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      count++;
      new_desc[idx].push({ ganti: "Lintas Minat" });
      for (let item of this.nilaiLimit) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      count++;
      new_desc[idx].push({ ganti: "Kelompok D (Kekhasan)" });
      for (let item of this.nilaiKelompok_D) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      this.newdeskripsi = new_desc;
      // console.log(new_desc);
    },
    getnilaipage() {
      let count = 0;
      let idx = 0;
      let new_nilai = [];

      new_nilai.push([]);
      count++;
      new_nilai[idx].push({ ganti: "Kelompok A (Wajib)" });
      for (let item of this.nilaiKelompok_A) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      count++;
      new_nilai[idx].push({ ganti: "Kelompok B (Wajib)" });
      for (let item of this.nilaiKelompok_B) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      count++;
      new_nilai[idx].push({ ganti: "Kelompok C (Peminatan)" });
      for (let item of this.nilaiKelompok_C) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      count++;
      new_nilai[idx].push({ ganti: "Lintas Minat" });
      for (let item of this.nilaiLimit) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      count++;
      new_nilai[idx].push({ ganti: "Kelompok D (Kekhasan)" });
      for (let item of this.nilaiKelompok_D) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      this.newformat = new_nilai;
      // console.log(new_nilai);
    },
    async getKehadiran() {
      let tempSiswa = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/hasil/raport/getkehadiran/${localStorage.getItem("id_tahun_ajaran")}`,
        this.siswa
      );
      for (let i in resp.data) {
        tempSiswa[i].kehadiran = resp.data[i];
      }
      this.siswa = tempSiswa;
    },
    async getPrestasi() {
      let tempSiswa = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/hasil/raport/getprestasi/${localStorage.getItem("id_tahun_ajaran")}`,
        this.siswa
      );
      for (let i in resp.data) {
        tempSiswa[i].prestasi = resp.data[i];
      }
      this.siswa = tempSiswa;
    },
    async getEkskul() {
      let tempSiswa = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/hasil/raport/getekskul/${localStorage.getItem("id_tahun_ajaran")}`,
        this.siswa
      );
      for (let i in resp.data) {
        tempSiswa[i].ekskul = resp.data[i];
      }
      this.siswa = tempSiswa;
    },
    prepareRerataKelas() {
      let rerataKelas = JSON.parse(JSON.stringify(this.rerataKelas));
      let total_pengetahuan = 0;
      let total_keterampilan = 0;
      let count = 0;
      for (let siswa of this.siswa) {
        for (let mapel of siswa.listmapel) {
          total_pengetahuan =
            total_pengetahuan + mapel.listNilai.nilai_pengetahuan;
          total_keterampilan =
            total_keterampilan + mapel.listNilai.nilai_keterampilan;
          count++;
        }
      }
      rerataKelas.pengetahuan = parseFloat(total_pengetahuan / count).toFixed(
        2
      );
      rerataKelas.keterampilan = parseFloat(total_keterampilan / count).toFixed(
        2
      );

      this.rerataKelas = rerataKelas;
    },
    getSummaryNilaiSiswa() {
      let total = JSON.parse(JSON.stringify(this.totalSiswa));
      let rerata = JSON.parse(JSON.stringify(this.rerataSiswa));
      let count = 0;

      for (let item of this.selSiswa.listmapel) {
        total.pengetahuan =
          total.pengetahuan + item.listNilai.nilai_pengetahuan;
        total.keterampilan =
          total.keterampilan + item.listNilai.nilai_keterampilan;

        count++;
      }
      rerata.pengetahuan = total.pengetahuan / count;
      rerata.keterampilan = total.keterampilan / count;

      this.totalSiswa = total;
      this.rerataSiswa = rerata;
    },
    getKelompok() {
      let tempSelSiswa = JSON.parse(JSON.stringify(this.selSiswa));
      let kelompok_A = tempSelSiswa.listmapel.filter(function(x) {
        return x.kelompok == "A : Wajib";
      });
      let kelompok_B = tempSelSiswa.listmapel.filter(function(x) {
        return x.kelompok == "B : Wajib";
      });
      let kelompok_C = tempSelSiswa.listmapel.filter(function(x) {
        return x.kelompok == "C : Peminatan";
      });
      let kelompok_LIMIT = tempSelSiswa.listmapel.filter(function(x) {
        return x.kelompok == "Lintas Minat";
      });
      let kelompok_D = tempSelSiswa.listmapel.filter(function(x) {
        return x.kelompok == "D : Kekhasan";
      });
      this.nilaiKelompok_A = kelompok_A;
      this.nilaiKelompok_B = kelompok_B;
      this.nilaiKelompok_C = kelompok_C;
      this.nilaiLimit = kelompok_LIMIT;
      this.nilaiKelompok_D = kelompok_D;
      let count = 1;
      for (let el of this.nilaiKelompok_A) {
        el.number = count++;
      }
      for (let el of this.nilaiKelompok_B) {
        el.number = count++;
      }
      for (let el of this.nilaiKelompok_C) {
        el.number = count++;
      }
      for (let el of this.nilaiLimit) {
        el.number = count++;
      }
      for (let el of this.nilaiKelompok_D) {
        el.number = count++;
      }
    },
    async getNilaiDetail() {
      let tempSiswa = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/hasil/raport/getnilaidetail/${localStorage.getItem(
          "id_tahun_ajaran"
        )}`,
        this.siswa
      );
      for (let i in resp.data) {
        let jum = 0;
        let count = 0;
        for (let j in tempSiswa[i].listmapel) {
          tempSiswa[i].listmapel[j].listNilai = resp.data[i][j];
          jum = jum + resp.data[i][j].nilai_sikap;
          count++;
        }
        tempSiswa[i].nilai_sikap = parseFloat(
          (parseFloat(jum / count) +
            parseFloat(tempSiswa[i].nilai_sikap_walas) +
            parseFloat(tempSiswa[i].nilai_sikap_pengasuhan)) /
            3
        ).toFixed(2);
      }
      this.siswa = tempSiswa;
    },
    async getNilaiAkademik() {
      let tempSiswa = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/hasil/raport/getidnilaiakademik/${localStorage.getItem(
          "id_tahun_ajaran"
        )}`,
        this.siswa
      );
      for (let i in resp.data) {
        tempSiswa[i].listmapel = resp.data[i];
      }
      this.siswa = tempSiswa;
    },
    async download2() {
      this.$q.loading.show();
      await this.saveRaport();
      const doc = new jsPDF("p", "mm", "a4");

      let data_cover = await html2canvas(this.$refs.cover, { scale: 2 });
      const cover = data_cover.toDataURL("image/png");
      let data_alamat = await html2canvas(this.$refs.alamat, { scale: 2 });
      const alamat = data_alamat.toDataURL("image/png");

      doc.addImage(cover, "JPEG", 0, 0, 210, 297, "aliasx", "MEDIUM");
      doc.addPage();
      doc.addImage(alamat, "JPEG", 0, 0, 210, 297, "aliasy", "MEDIUM");
      doc.addPage();

      let data_header = await html2canvas(this.$refs.header, { scale: 2 });
      const header = data_header.toDataURL("image/png");
      let data_ttd = await html2canvas(this.$refs.ttd, { scale: 2 });
      const ttd = data_ttd.toDataURL("image/png");

      //ukuran data pada halaman
      var imgWidth = 210;
      var pageHeight = 215;

      //sikap
      let result = await html2canvas(this.$refs.sikap, { scale: 2 });
      var imgHeight = (result.height * imgWidth) / result.width;
      var heightLeft = imgHeight;

      var gambar = result.toDataURL("image/png");

      var position = 60;
      doc.addImage(header, "JPEG", 0, 0, 210, 50, "alias1", "MEDIUM");
      doc.addImage(
        gambar,
        "JPEG",
        0,
        position,
        imgWidth,
        imgHeight + 15,
        "sikap",
        "MEDIUM"
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(header, "JPEG", 0, 0, 210, 50, "alias1", "MEDIUM");
        doc.addImage(
          gambar,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight + 15,
          "sikap",
          "MEDIUM"
        );
        heightLeft -= pageHeight;
      }
      doc.addImage(ttd, "JPEG", 0, 260, 210, 30, "alias2", "MEDIUM");

      //nilai
      doc.addPage();
      result = await html2canvas(this.$refs.nilai, { scale: 2 });
      imgHeight = (result.height * imgWidth) / result.width;
      heightLeft = imgHeight;

      gambar = result.toDataURL("image/png");

      position = 60;
      doc.addImage(header, "JPEG", 0, 0, 210, 50, "alias1", "MEDIUM");
      doc.addImage(
        gambar,
        "JPEG",
        0,
        position,
        imgWidth,
        imgHeight + 15,
        "nilai",
        "MEDIUM"
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(header, "JPEG", 0, 0, 210, 50, "alias1", "MEDIUM");
        doc.addImage(
          gambar,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight + 15,
          "nilai",
          "MEDIUM"
        );
        heightLeft -= pageHeight;
      }
      doc.addImage(ttd, "JPEG", 0, 260, 210, 30, "alias2", "MEDIUM");

      doc.save("a4.pdf");
      this.$q.loading.hide();
    },

    async getListSiswa() {
      let resp = await this.$http.get(
        `/hasil/raport/getdatasiswa/${
          this.selKelas.value
        }/${localStorage.getItem("id_tahun_ajaran")}`
      );
      for (let item of resp.data) {
        item.alamat_detail = `${item.alamat} Kel.${item.kelurahan} ${item.kecamatan}`;
        if (parseInt(moment().format("MM")) > 6) {
          item.semester = `1 (Satu) / Ganjil`;
          item.tahun_ajar = `${moment().format("YYYY")}/${parseInt(
            moment().format("YYYY")
          ) + 1}`;
        } else {
          item.semester = `2 (Dua) / Genap`;
          item.tahun_ajar = `${parseInt(moment().format("YYYY")) -
            1}/${moment().format("YYYY")}`;
        }
      }
      this.siswa = resp.data;
    },
  },
};
</script>

<style lang="scss">
.text-predikat {
  font-size: 3em !important;
}
.text-lebih-kecil {
  font-size: 0.85em !important;
}
.text-kecil {
  font-size: 1em !important;
}
.text-besar {
  font-size: 1.18em !important;
}
.paper {
  width: 210mm;
  height: 297mm;
  padding: 1cm 1.9cm 1cm 1.9cm;
}
</style>
